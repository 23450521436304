export const contsRewardManagerAbi = [
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: 'enum ContType', name: '_cType', type: 'uint8' },
      { indexed: false, internalType: 'uint256', name: 'delta', type: 'uint256' },
    ],
    name: 'BreakevenChanged',
    type: 'event',
  },
  {
    inputs: [],
    name: 'FOUR_MONTH',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'MAXUINT256',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'ONE_MONTH',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'THREE_MONTH',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'TWO_MONTH',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: '', type: 'address' },
      { internalType: 'uint256', name: '', type: 'uint256' },
    ],
    name: '_brokeevenContract',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
    name: '_cashoutAllContsReward',
    outputs: [
      { internalType: 'uint256', name: '', type: 'uint256' },
      { internalType: 'uint256', name: '', type: 'uint256' },
      { internalType: 'uint256', name: '', type: 'uint256' },
      { internalType: 'uint256', name: '', type: 'uint256' },
    ],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'account', type: 'address' },
      { internalType: 'uint256', name: '_contIndex', type: 'uint256' },
    ],
    name: '_cashoutContReward',
    outputs: [
      { internalType: 'uint256', name: '', type: 'uint256' },
      { internalType: 'enum ContType', name: '', type: 'uint8' },
    ],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint256', name: 'newInterval', type: 'uint256' }],
    name: '_changeAutoReduceAPRInterval',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint256', name: 'newRate', type: 'uint256' }],
    name: '_changeAutoReduceAPRRate',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint256', name: 'newTime', type: 'uint256' }],
    name: '_changeCashoutTimeout',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'enum ContType', name: '_cType', type: 'uint8' },
      { internalType: 'uint256', name: 'newPrice', type: 'uint256' },
    ],
    name: '_changeContPrice',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'enum ContType', name: '_cType', type: 'uint8' },
      { internalType: 'int256', name: 'reducedPercentage', type: 'int256' },
    ],
    name: '_changeRewardAPRPerCont',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
    name: '_getClaimedAmountOf',
    outputs: [
      { internalType: 'uint256', name: 'total', type: 'uint256' },
      { internalType: 'string', name: 'list', type: 'string' },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'account', type: 'address' },
      { internalType: 'uint256', name: '_contIndex', type: 'uint256' },
    ],
    name: '_getClaimedAmountOfIndex',
    outputs: [{ internalType: 'uint256', name: 'rewardCont', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
    name: '_getContNumberOf',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
    name: '_getContsCreationTime',
    outputs: [{ internalType: 'string', name: '', type: 'string' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
    name: '_getContsCurrentAPR',
    outputs: [{ internalType: 'string', name: '', type: 'string' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
    name: '_getContsInitialAPR',
    outputs: [{ internalType: 'string', name: '', type: 'string' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
    name: '_getContsLastUpdateTime',
    outputs: [{ internalType: 'string', name: '', type: 'string' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
    name: '_getContsNames',
    outputs: [{ internalType: 'string', name: '', type: 'string' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
    name: '_getContsRewardAvailable',
    outputs: [{ internalType: 'string', name: '', type: 'string' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
    name: '_getContsTypes',
    outputs: [{ internalType: 'string', name: '', type: 'string' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
    name: '_getRewardAmountOf',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'account', type: 'address' },
      { internalType: 'uint256', name: '_contIndex', type: 'uint256' },
    ],
    name: '_getRewardAmountOfIndex',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
    name: '_isContOwner',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'enum ContType', name: '_cType', type: 'uint8' }],
    name: '_undoRewardAPRChange',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: '', type: 'address' },
      { internalType: 'uint256', name: '', type: 'uint256' },
    ],
    name: 'additionalDataContract',
    outputs: [
      { internalType: 'uint256', name: 'expireIn', type: 'uint256' },
      { internalType: 'uint256', name: 'lastUpdated', type: 'uint256' },
      { internalType: 'bool', name: 'isFeeContract', type: 'bool' },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'admin0XB',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'autoReduceAPRInterval',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'autoReduceAPRRate',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'cashoutTimeout',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'enum ContType', name: '_cType', type: 'uint8' },
      { internalType: 'uint256', name: '_newFee', type: 'uint256' },
    ],
    name: 'changeFeeInMonth',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: '_feeToken', type: 'address' }],
    name: 'changeFeeToken',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'bool', name: '_status', type: 'bool' }],
    name: 'changeMonthFeeState',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'enum ContType', name: '', type: 'uint8' }],
    name: 'contPrice',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'account', type: 'address' },
      { internalType: 'string[]', name: 'contNames', type: 'string[]' },
      { internalType: 'enum ContType', name: '_cType', type: 'uint8' },
    ],
    name: 'createConts',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'account', type: 'address' },
      { internalType: 'string[]', name: 'contNames', type: 'string[]' },
      { internalType: 'enum ContType', name: '_cType', type: 'uint8' },
    ],
    name: 'createContsOldForTest',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'enum ContType', name: '_cType', type: 'uint8' }],
    name: 'currentRewardAPRPerNewCont',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'decreaseFeePercent',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'defaultExpireIn',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'uint256[]', name: 'time', type: 'uint256[]' },
      { internalType: 'uint256[]', name: 'indexes', type: 'uint256[]' },
    ],
    name: 'extendContract',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'enum ContType', name: '', type: 'uint8' }],
    name: 'feeInMonth',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'feeToken',
    outputs: [{ internalType: 'contract IERC20', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'user', type: 'address' },
      { internalType: 'uint256', name: 'index', type: 'uint256' },
    ],
    name: 'getExpireIn',
    outputs: [
      {
        components: [
          { internalType: 'uint256', name: 'expireIn', type: 'uint256' },
          { internalType: 'uint256', name: 'lastUpdated', type: 'uint256' },
          { internalType: 'bool', name: 'isFeeContract', type: 'bool' },
        ],
        internalType: 'struct CONTRewardManagement.AdditionalDataEntity',
        name: '',
        type: 'tuple',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'account', type: 'address' },
      { internalType: 'uint256[]', name: 'time', type: 'uint256[]' },
      { internalType: 'uint256[]', name: 'indexes', type: 'uint256[]' },
    ],
    name: 'getExtendContractFee',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: 'user', type: 'address' }],
    name: 'getFullDataAllCont',
    outputs: [
      {
        components: [
          { internalType: 'string', name: 'name', type: 'string' },
          { internalType: 'uint256', name: 'creationTime', type: 'uint256' },
          { internalType: 'uint256', name: 'lastUpdateTime', type: 'uint256' },
          { internalType: 'uint256', name: 'initialAPR', type: 'uint256' },
          { internalType: 'uint256', name: 'buyPrice', type: 'uint256' },
          { internalType: 'enum ContType', name: 'cType', type: 'uint8' },
          { internalType: 'uint256', name: 'expireIn', type: 'uint256' },
          { internalType: 'uint256', name: 'lastUpdated', type: 'uint256' },
          { internalType: 'bool', name: 'isFeeContract', type: 'bool' },
          { internalType: 'uint256', name: 'reward', type: 'uint256' },
          { internalType: 'uint256', name: 'claimed', type: 'uint256' },
        ],
        internalType: 'struct CONTRewardManagement.FullDataEntity[]',
        name: '',
        type: 'tuple[]',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'user', type: 'address' },
      { internalType: 'uint256', name: 'index', type: 'uint256' },
    ],
    name: 'getFullDataCont',
    outputs: [
      {
        components: [
          { internalType: 'string', name: 'name', type: 'string' },
          { internalType: 'uint256', name: 'creationTime', type: 'uint256' },
          { internalType: 'uint256', name: 'lastUpdateTime', type: 'uint256' },
          { internalType: 'uint256', name: 'initialAPR', type: 'uint256' },
          { internalType: 'uint256', name: 'buyPrice', type: 'uint256' },
          { internalType: 'enum ContType', name: 'cType', type: 'uint8' },
          { internalType: 'uint256', name: 'expireIn', type: 'uint256' },
          { internalType: 'uint256', name: 'lastUpdated', type: 'uint256' },
          { internalType: 'bool', name: 'isFeeContract', type: 'bool' },
          { internalType: 'uint256', name: 'reward', type: 'uint256' },
          { internalType: 'uint256', name: 'claimed', type: 'uint256' },
        ],
        internalType: 'struct CONTRewardManagement.FullDataEntity',
        name: '',
        type: 'tuple',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'enum ContType', name: '', type: 'uint8' }],
    name: 'initRewardAPRPerCont',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'uint256[]', name: '_contPrices', type: 'uint256[]' },
      { internalType: 'uint256[]', name: '_rewardAPRs', type: 'uint256[]' },
      { internalType: 'uint256', name: '_cashoutTimeout', type: 'uint256' },
      { internalType: 'uint256', name: '_autoReduceAPRRate', type: 'uint256' },
    ],
    name: 'initialize',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'account', type: 'address' },
      { internalType: 'uint256', name: 'index', type: 'uint256' },
    ],
    name: 'isExpiredCont',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'user', type: 'address' },
      { internalType: 'uint256', name: 'index', type: 'uint256' },
    ],
    name: 'isFeeContract',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'isMonthFeeActive',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'maxIndexMonthFeeLogs',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    name: 'monthFeeLogs',
    outputs: [
      { internalType: 'uint256', name: 'currentTime', type: 'uint256' },
      { internalType: 'bool', name: 'state', type: 'bool' },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: 'newAdmin', type: 'address' }],
    name: 'setAdmin',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: 'token_', type: 'address' }],
    name: 'setToken',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: '_feeToken', type: 'address' },
      { internalType: 'uint256', name: '_tesseractFee', type: 'uint256' },
      { internalType: 'uint256', name: '_cubeFee', type: 'uint256' },
      { internalType: 'uint256', name: '_squareFee', type: 'uint256' },
      { internalType: 'uint256', name: '_defaultExpireIn', type: 'uint256' },
    ],
    name: 'setupDataForMonthFee',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint256', name: '_defaultExpireIn', type: 'uint256' }],
    name: 'setupDataForMonthFeeTest',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'token',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'totalContsCreated',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'enum ContType', name: '_cType', type: 'uint8' }],
    name: 'totalContsPerContType',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: '_user', type: 'address' }],
    name: 'withdrawFeeToken',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
];
